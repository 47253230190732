import * as types from './types';
import {emptyAppInfo} from "../util/constants";


const initialState = {
    userToken: '',
    loginToggle: false,
    userCookie: '',
    selectedTopMenuOption: '',
    railDisplay: 'none',
    menuOptions: [],
    userPrincipal: {
        unauthenticatedUser: false,
        email: '',
        firstName: '',
        lastName: '',
        userId: '',
        userLevel: '',
        displayName: ''
    },
    darkTheme: false,
    termsAccepted: true,
    appInfo: emptyAppInfo,
    sessionAnnouncements: [],
    portalProperties: {
        environmentClass: '',
        environmentName: '',
        environmentShortName: '',
        hrefSppOrg: '',
        hrefOasis: '',
        hrefOps1: '',
        hrefImpactStudies: '',
        hrefRms: '',
        hrefNameSppOrg: '',
        hrefNameOasis: '',
        hrefNameOps1: '',
        hrefNameImpactStudies: '',
        hrefNameRms: '',
        uaaHost: '',
        portalHost: '',
        apiVersion: '',
        googleAnalytics: 'G-00000',
        maxMenuDepth: ''
    },
    features: {}
};

// TODO - adding our actions for udpating the token in our state.
export const reducer = (state = initialState, action:any) => {
    switch(action.type) {
        case types.GET_USER_PRINCIPAL:
            return {
                ...state,
                userPrincipal: action.payload
            };
        case types.TOGGLE_LOGIN:
            return {
                ...state,
                loginToggle: action.payload
            };
        case types.CHANGE_TOKEN:
            return {
                ...state,
                userToken: action.payload
            };
        case types.SET_COOKIE:
            return {
                ...state,
                userCookie: action.payload
            };
        case types.GET_APP_INFO:
            return {
                ...state,
                appInfo: action.payload
            }
        case types.GET_SELECTED_TOP_MENU_OPTION:
            return {
                ...state,
                selectedTopMenuOption: action.payload
            }
        case types.GET_RAIL_DISPLAY:
            return {
                ...state,
                railDisplay: action.payload
            }
        case types.GET_MENU_OPTIONS:
            return {
                ...state,
                menuOptions: action.payload
            }
        case types.GET_PORTAL_PROPERTIES:
            return {
                ...state,
                portalProperties: action.payload
            }
        case types.GET_DARK_THEME:
            return {
                ...state,
                darkTheme: action.payload
            }
        case types.GET_TERMS_ACCEPTED:
            return {
                ...state,
                termsAccepted: action.payload
            }
        case types.GET_SESSION_ANNOUNCEMENTS:
            return {
                ...state,
                sessionAnnouncements: action.payload
            }
        case types.GET_FEATURES:
            return {
                ...state,
                features: action.payload
            }
    }
    return state;
};


