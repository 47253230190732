import * as React from 'react';
import './index.css'
import {MenuOption, PortalProperties, Principal} from "../../../models/models";
import MenuRail from "../../Common/MenuRail";
import {Grid, Segment, List, Loader, Dimmer, ListItem, ListItemProps,} from "semantic-ui-react";
import {getMenu} from "../../../services/menu-service";
import ReactGA from "react-ga4";
import {cloneDeep} from "lodash";
import {getMenuOptionHref} from "../../../util/functions";

interface PageProps {
    userCookie: any
    userPrincipal: Principal;
    history: any
    selectedTopMenuOption: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    portalProperties: PortalProperties;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    menuOptions: MenuOption[]
    darkTheme: boolean
}

interface PageState {
    menuOptions: MenuOption[]
    loading: boolean
    legend: React.JSX.Element[],
    viewWidth: number,
    selectedLegend: string
    showLegend: boolean
}

export default class Sitemap extends React.Component<PageProps, PageState> {

    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            menuOptions: [],
            loading: true,
            legend: [],
            viewWidth: 0,
            selectedLegend: '',
            showLegend: false
        }
    }

    componentDidMount() {
        this.getMenuOptions()
        ReactGA.event({category: "user_page_view", action: "View Site Map"});
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ viewWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if(this.state.loading !== prevState.loading)
        {
            if (!this.state.loading && this.state.viewWidth > 900)
                this.setState({showLegend: true})

            if (this.state.loading)
                this.setState({showLegend: false})
        }

        if (this.state.viewWidth !== prevState.viewWidth)
        {
            if (this.state.viewWidth > 900 && !this.state.loading)
                this.setState({showLegend: true})

            if (this.state.viewWidth < 900)
                this.setState({showLegend: false})
        }
    }

    addToLegend = (entry: React.JSX.Element) => {
        if (!this.state.legend.some(item => item.props.id === entry.props.id))
            this.state.legend.push(entry)
    }

    getMenuOptions = () => {
        getMenu(this.props.userCookie).then(response => {
            if (response.length > 0)
                response = response.filter(option => option.name !== 'Welcome')

            this.setState({menuOptions: response, loading: false})
        })
    }

    legendItemOnClick = (event: any, {name}: ListItemProps) => {
        let selectedLegend = this.state.selectedLegend === name ? '' : name
        let newLegend = cloneDeep(this.state.legend)

        newLegend.forEach(element => {
            element.props.className = (selectedLegend === element.props.name ? 'legend-item legend-selected' : 'legend-item')})

        this.setState({legend: newLegend, selectedLegend})
    }

    getSitemapFromMenu = () => {
        let getClassname = (option: MenuOption, link: boolean) => {
            let linkClass = link ? ' link' : '';
            if (option.pageType === 'static')
            {
                if (option.slug === 'your-certificate-information')
                    return (this.state.selectedLegend === 'cert' && this.state.showLegend) ?
                        'sm-list' + linkClass + ' legend-selected' : 'sm-list'+ linkClass
                else if (option.name === 'Integrated Marketplace Generation Mix')
                    return (this.state.selectedLegend === 'pie' && this.state.showLegend) ?
                        'sm-list' + linkClass + ' legend-selected' : 'sm-list' + linkClass
                else
                    return (this.state.selectedLegend === 'chart' && this.state.showLegend) ?
                        'sm-list' + linkClass + ' legend-selected' : 'sm-list' + linkClass
            }

            if (option.slug === 'admin-pages' || option.slug === 'local-security-administration' || option.slug === 'user-administration'
                    || option.slug === 'my-account' || option.slug === 'feature-console')
                return (this.state.selectedLegend === 'admin' && this.state.showLegend) ?
                    'sm-list' + linkClass + ' legend-selected' : 'sm-list' + linkClass

            if (option.type === 'PAGE_GROUP')
                return (this.state.selectedLegend === option.type.toLowerCase() && this.state.showLegend) ?
                    'sm-list' + linkClass + ' legend-selected' : 'sm-list' + linkClass

            return (this.state.selectedLegend === option.pageType && this.state.showLegend) ?
                'sm-list' + linkClass + ' legend-selected' : 'sm-list' + linkClass
        }

        let getListItem = (option: MenuOption) => {
            if (option.childList.length > 0)
                return (
                    <List.Item
                        className={getClassname(option, false)}
                        key={option.name}>
                        <List.Icon name={this.getSiteMapIcons(option)} />
                        <List.Content>
                            <List.Header>
                                {option.name}
                            </List.Header>
                            {getChildren(option.childList)}
                        </List.Content>
                    </List.Item>
                )
            else
                return (
                    <List.Item
                        className={getClassname(option, true)}
                        as={'a'}
                        href={getMenuOptionHref(option, this.props.portalProperties.uaaHost, this.props.portalProperties.portalHost)}
                        target={'_blank'}
                        rel={"noreferrer"}
                        key={option.name}>
                        <List.Icon name={this.getSiteMapIcons(option)} />
                        <List.Content>
                            <List.Header>
                                {option.name}
                            </List.Header>
                            {getChildren(option.childList)}
                        </List.Content>
                    </List.Item>
                )
        }

        let getChildren = (children: MenuOption[]) => {
            if (children.length > 0 )
            {
                let childArray: any = [];
                children.forEach(child => {childArray.push(getListItem(child))})
                return (
                    <List.List className={this.props.darkTheme? 'dark' : ''}>
                        {childArray}
                    </List.List>
                )
            }
            return null
        }

        let optionArray: any = []
        if(this.state.menuOptions.length > 0)
            this.state.menuOptions.forEach(option => {optionArray.push(getListItem(option))})

        return (
            <List className={this.props.darkTheme? 'dark' : ''}>
                {optionArray}
            </List>
        )
    }

    //Adds legend options and returns icon names
    getSiteMapIcons = (child: MenuOption) => {
        switch (child.pageType? child.pageType : child.type) {
            case 'file-browser':
                this.addToLegend(
                    <ListItem
                        id={'fb-legend'}
                        name={'file-browser'}
                        key={'fb'}
                        className={'legend-item'}
                        icon={'copy'}
                        onClick={this.legendItemOnClick}
                        content={'File Browsing Page'}/>)
                return 'copy'

            case 'external':
                this.addToLegend(
                    <ListItem
                        id={'external-legend'}
                        name={'external'}
                        className={'legend-item'}
                        key={'external'}
                        icon={'sign out'}
                        onClick={this.legendItemOnClick}
                        content={'External Page'}/>)
                return 'sign out'

            case 'embedded':
                this.addToLegend(
                    <ListItem
                        id={'embedded-legend'}
                        name={'embedded'}
                        className={'legend-item'}
                        key={'embedded'}
                        icon={'window maximize outline'}
                        onClick={this.legendItemOnClick}
                        content={'Embedded Page'}/>)
                return 'window maximize outline'

            case 'tableau':
                this.addToLegend(
                    <ListItem
                        id={'tableau-legend'}
                        name={'tableau'}
                        className={'legend-item'}
                        key={'tableau'}
                        icon={'area chart'}
                        onClick={this.legendItemOnClick}
                        content={'Tableau Page'}/>)
                return 'area chart'

            case 'static':
                if (child.slug === 'your-certificate-information')
                {
                    this.addToLegend(
                        <ListItem
                            id={'cert-legend'}
                            name={'cert'}
                            className={'legend-item'}
                            key={'cert'}
                            icon={'certificate'}
                            onClick={this.legendItemOnClick}
                            content={'Certificate Information Page'}/>)
                    return 'certificate'
                }
                else if (child.name === 'Integrated Marketplace Generation Mix')
                {
                    this.addToLegend(
                        <ListItem
                            id={'pei-legend'}
                            key={'pie'}
                            name={'pie'}
                            className={'legend-item'}
                            icon={'pie chart'}
                            onClick={this.legendItemOnClick}
                            content={'Pie Chart'}/>)
                    return 'pie chart'
                }
                else {
                    this.addToLegend(
                        <ListItem
                            id={'line-legend'}
                            name={'chart'}
                            className={'legend-item'}
                            key={'line'}
                            icon={'chart line'}
                            onClick={this.legendItemOnClick}
                            content={'Line Chart'}/>)
                    return 'chart line'
                }

            default:
                if (child.slug === 'admin-pages')
                {
                    this.addToLegend(
                        <ListItem
                            id={'admin-legend'}
                            key={'admin'}
                            name={'admin'}
                            onClick={this.legendItemOnClick}
                            className={'legend-item'}
                            icon={'setting'}
                            content={'Administration Page'}/>)
                    return "setting"
                }
                else if (child.slug === 'user-administration') {
                    this.addToLegend(
                        <ListItem
                            id={'admin-legend'}
                            key={'admin'}
                            name={'admin'}
                            onClick={this.legendItemOnClick}
                            className={'legend-item'}
                            icon={'setting'}
                            content={'Administration Page'}/>)
                    return 'setting'
                }
                else if (child.slug === 'local-security-administration') {
                    this.addToLegend(
                        <ListItem
                            id={'admin-legend'}
                            key={'admin'}
                            name={'admin'}
                            onClick={this.legendItemOnClick}
                            className={'legend-item'}
                            icon={'setting'}
                            content={'Administration Page'}/>)
                    return 'setting'
                }
                else if (child.slug === 'my-account')
                {
                    this.addToLegend(
                        <ListItem
                            id = {'admin-legend'}
                            key={'admin'}
                            name={'admin'}
                            onClick={this.legendItemOnClick}
                            className={'legend-item'}
                            icon={'setting'}
                            content={'Administration Page'}/>)
                    return 'setting'
                }
                else if (child.slug === 'feature-console')
                {
                    this.addToLegend(
                        <ListItem
                            id = {'admin-legend'}
                            key={'admin'}
                            name={'admin'}
                            onClick={this.legendItemOnClick}
                            className={'legend-item'}
                            icon={'setting'}
                            content={'Administration Page'}/>)
                    return 'setting'
                }

                this.addToLegend(
                    <ListItem
                        id={'pg-legend'}
                        name={'page_group'}
                        onClick={this.legendItemOnClick}
                        className={'legend-item'}
                        key={'pg'}
                        icon={'list'}
                        content={'Menu Page'}/>)
                return 'list'
        }
    }

    getLegend = () => {
        if (this.state.showLegend)
        {
            return(
                <Segment.Group
                    compact={true}
                    className={this.props.darkTheme? 'legend dark' : 'legend'}
                    id={'sm-seg-legend'} >
                    <Segment
                        content={"Sitemap Legend"}
                        className={'chart-title-sg'}/>
                    <Segment id={'sg-legend-bdy'}>
                        <List>
                            {this.state.legend}
                        </List>
                    </Segment>
                </Segment.Group>
            )

        }
        else return false
    }

    render() {
        return (
            <div id={'top-level-bkg'} className={this.props.darkTheme? 'background-image-tou dark' : 'background-image-tou light'}>
                <MenuRail selectedTopMenuItem={this.props.selectedTopMenuOption}
                          railDisplay={this.props.railDisplay}
                          setRailDisplay={this.props.setRailDisplay}
                          userPrincipal={this.props.userPrincipal}
                          portalProperties={this.props.portalProperties}
                          history={this.props.history}
                          menuOptions={this.props.menuOptions}
                          setSelectedTopMenuOption={this.props.setSelectedTopMenuOption}/>
                <Grid id={'sitemap-grid'}>
                    <Grid.Column id={'sm-column'} >
                        <Segment.Group className={this.props.darkTheme? 'dark' : ''} id={'sm-seg'} >
                            <Segment
                                content={"SPP Portal Sitemap"}
                                className={'chart-title-sg'}/>
                            <Segment id={'sm-body-sg'} >
                                <Dimmer active={this.state.loading} inverted={!this.props.darkTheme}>
                                    <Loader content={'Loading'}/>
                                </Dimmer>
                                {this.getLegend()}
                                {this.getSitemapFromMenu()}
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid>
            </div>)
    }
}
