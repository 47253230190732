import * as React from 'react';
import './index.css'
import {Grid, Menu, Rail, Segment} from "semantic-ui-react";
import {emptyMenuOption} from "../../../util/constants";
import {MenuOption, PortalProperties, Principal} from "../../../models/models";
import {cloneDeep} from "lodash";
import {NavLink} from "react-router-dom";
import {getMenuOptionHref} from "../../../util/functions";


interface PageProps {
    selectedTopMenuItem: string
    setSelectedTopMenuOption: (selectedTopMenuOption: string) => void;
    railDisplay: string
    setRailDisplay: (railDisplay: string) => void
    menuOptions: MenuOption[]
    userPrincipal: Principal
    portalProperties: PortalProperties;
    history: any
}

interface PageState {
    menu1Selected: MenuOption
    menu2Selected: MenuOption
    menu3Selected: MenuOption
    menu4Selected: MenuOption
}

export default class MenuRail extends React.Component<PageProps, PageState> {
    constructor(props: PageProps, state: PageState) {
        super(props, state);

        this.state = {
            menu1Selected: emptyMenuOption,
            menu2Selected: emptyMenuOption,
            menu3Selected: emptyMenuOption,
            menu4Selected: emptyMenuOption,
        }
    }

    componentDidMount() {
       if (this.props.selectedTopMenuItem !== '')
           this.getMenuOptions(this.getFirstMenuParent(), 1)
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any) {
        if (prevProps.selectedTopMenuItem !== this.props.selectedTopMenuItem)
        {
            this.setState({
                menu1Selected: emptyMenuOption,
                menu2Selected: emptyMenuOption,
                menu3Selected: emptyMenuOption,
                menu4Selected: emptyMenuOption
            });

            if (this.props.selectedTopMenuItem !== '')
                this.props.setRailDisplay('');

            this.getMenuOptions(this.getFirstMenuParent(), 1)
        }

        if (prevState.menu1Selected !== this.state.menu1Selected)
            this.setState({
                menu2Selected: emptyMenuOption,
                menu3Selected: emptyMenuOption,
                menu4Selected: emptyMenuOption
            })

        if (prevState.menu2Selected !== this.state.menu2Selected)
            this.setState({
                menu3Selected: emptyMenuOption,
                menu4Selected: emptyMenuOption
            })

        if (prevState.menu3Selected !== this.state.menu3Selected)
        {
            this.setState({
                menu4Selected: emptyMenuOption
            })
        }
    }

    publicChild = (selectedOption: string, publicOption: MenuOption|undefined): boolean => {
        if(publicOption !== undefined)
           return publicOption.childList.filter(o => o.name === selectedOption).length >0

        return false
    }

    getFirstMenuParent = () => {

        if (this.props.selectedTopMenuItem === 'overflow')
        {
            let overflow: MenuOption = cloneDeep(emptyMenuOption);
            overflow.childList = this.props.menuOptions

            return overflow
        }

        let selectedMenuOption;
        let publicOption = this.props.menuOptions.find(o => o.name === 'Public');
        if (this.publicChild(this.props.selectedTopMenuItem, publicOption) && this.props.userPrincipal.unauthenticatedUser)
        {
            if(publicOption !== undefined)
                selectedMenuOption  = publicOption.childList.find(o => o.name === this.props.selectedTopMenuItem)
        }
        else
            selectedMenuOption  = this.props.menuOptions.find(o => o.name === this.props.selectedTopMenuItem)

        if (selectedMenuOption !== undefined)
            return selectedMenuOption;
        else
            return emptyMenuOption;
    }

    getMenuItemSelected = (menuNumber: number, name: string) => {
        let selected = '';
        switch (menuNumber)
        {
            case 1:
                if (this.state.menu1Selected.name === name)
                    selected =  '-selected'
                break;
            case 2:
                if (this.state.menu2Selected.name === name)
                    selected =  '-selected'
                break;
            case 3:
                if (this.state.menu3Selected.name === name)
                    selected =  '-selected'
                break;
            case 4:
                if (this.state.menu4Selected.name === name)
                    selected =  '-selected'
                break;
        }
        return selected
    }

    setSelectedState = (menuNumber: number, option: MenuOption) => {
        switch (menuNumber)
        {
            case 1:
                this.setState({menu1Selected: option})
                break;
            case 2:
                this.setState({menu2Selected: option})
                break;
            case 3:
                this.setState({menu3Selected: option})
                break;
            case 4:
                this.setState({menu4Selected: option})
                break;
        }
    }

    setGridColumnClassname = (menuNumber: number) => {
        switch (menuNumber)
        {
            case 2:
                if (this.state.menu1Selected.childList.length > 0)
                    return 'rail-menu-column-children'
                else
                    return 'rail-menu-column'
            case 3:
                if (this.state.menu2Selected.childList.length > 0)
                    return 'rail-menu-column-children'
                else
                    return 'rail-menu-column'
            case 4:
                if (this.state.menu3Selected.childList.length > 0)
                    return 'rail-menu-column-children'
                else
                    return 'rail-menu-column'
            case 5:
                if (this.state.menu4Selected.childList.length > 0)
                    return 'rail-menu-column-children'
                else
                    return 'rail-menu-column'
            default:
                return 'rail-menu-column'
        }
    }

    addSubmenuTitle = (level: number, menuItemArray: any[]) => {
        if (menuItemArray.length === 0)
            return menuItemArray;

        switch (level)
        {
            case 1:
                if (this.props.selectedTopMenuItem.length > 0 && this.props.selectedTopMenuItem !== 'overflow')
                menuItemArray.unshift(
                    <Menu.Item
                        key={this.props.selectedTopMenuItem+'-top-level'}
                        className={'rail-menu-item header'}>
                        {this.props.selectedTopMenuItem}
                    </Menu.Item>
                )
                return menuItemArray
            case 2:
                menuItemArray.unshift(
                    <Menu.Item
                        key={this.state.menu1Selected.name+'-'+this.state.menu1Selected.slug}
                        className={'rail-menu-item header'}>
                        {this.state.menu1Selected.name}
                    </Menu.Item>
                )
                return menuItemArray
            case 3:
                menuItemArray.unshift(
                    <Menu.Item
                        key={this.state.menu2Selected.name+'-'+this.state.menu2Selected.slug}
                        className={'rail-menu-item header'}>
                        {this.state.menu2Selected.name}
                    </Menu.Item>
                )
                return menuItemArray
            case 4:
                menuItemArray.unshift(
                    <Menu.Item
                        key={this.state.menu3Selected.name+'-'+this.state.menu3Selected.slug}
                        className={'rail-menu-item header'}>
                        {this.state.menu3Selected.name}
                    </Menu.Item>
                )
               return menuItemArray
            case 5:
                menuItemArray.unshift(
                    <Menu.Item
                        key={this.state.menu4Selected.name+'-'+this.state.menu4Selected.slug}
                        className={'rail-menu-item header'}>
                        {this.state.menu4Selected.name}
                    </Menu.Item>
                )
                return menuItemArray;

            default:
                return menuItemArray;

        }
    }

    menuItemOnClick = (option: MenuOption) => {
        this.props.setRailDisplay('none')
        this.props.setSelectedTopMenuOption('');
    }



    getItemContent = (item: MenuOption, level: number) => {
        if ((item.type === 'PAGE' && item.pageType === 'external') || item.slug === 'user-administration'
            || item.slug === 'local-security-administration' || item.slug === 'admin-pages' || item.slug === 'my-account'
            || item.slug === 'feature-console')
        {
            return (
                <a
                    href={getMenuOptionHref(item, this.props.portalProperties.uaaHost, this.props.portalProperties.portalHost)
                         }
                    target={"_blank"}
                    id={(item.name.replace(/ /g, '-')).toLowerCase()}
                    rel="noopener noreferrer"
                    onClick={() => this.menuItemOnClick(item)}
                    onMouseOver={() => this.setSelectedState(level, item)}
                    className={'rail-menu-item bold-on-hover'+this.getMenuItemSelected(level, item.name)}>
                    {item.name}
                </a>
            )
        }
        else
            return (
                <NavLink
                    to={getMenuOptionHref(item, this.props.portalProperties.uaaHost)}
                    onMouseOver={() => this.setSelectedState(level, item)}
                    id={(item.name.replace(/ /g, '-')).toLowerCase()}
                    onClick={() => this.menuItemOnClick(item)}
                    className={'rail-menu-item bold-on-hover'+this.getMenuItemSelected(level, item.name)}>
                    {item.name}
                </NavLink>
            )
    }

    getMenuOptions = (parent: MenuOption, level: number) => {
        let menuItemArray: any = [];

        if (parent.childList.length > 0  )
            parent.childList.forEach(item => {
                menuItemArray.push(
                    <Menu.Item
                        key={item.name+'-'+item.slug}
                        custom={item}>
                        {this.getItemContent(item, level)}
                    </Menu.Item>)
            })

        menuItemArray = this.addSubmenuTitle(level, menuItemArray);
        return menuItemArray;
    }

    render(){
        return(
            <Rail
                position={'left'}
                attached={true}
                internal={true}
                style={{display: this.props.railDisplay}}
                id={'menu-rail'}>
                <Segment id={'submenu-segment'} style={{display: this.props.railDisplay}}>
                    <Grid
                        id={'menu-grid'}
                        columns={'equal'}
                        divided={true}
                        onMouseLeave={() => this.props.setRailDisplay('none')}
                        onMouseOver={() => this.props.setRailDisplay('')}>
                        <Grid.Column className={this.setGridColumnClassname(1)}>
                            <Menu
                                borderless={true}
                                className={'rail-sub-menu'}
                                fluid={true}
                                vertical={true}
                                children={this.getMenuOptions(this.getFirstMenuParent(),1)}/>
                        </Grid.Column>
                        <Grid.Column className={this.setGridColumnClassname(2)}>
                            <Menu
                                borderless={true}
                                vertical={true}
                                fluid={true}
                                className={'rail-sub-menu'}
                                children={this.getMenuOptions(this.state.menu1Selected, 2)}/>
                        </Grid.Column>
                        <Grid.Column className={this.setGridColumnClassname(3)}>
                            <Menu
                                borderless={true}
                                vertical={true}
                                fluid={true}
                                className={'rail-sub-menu'}
                                children={this.getMenuOptions(this.state.menu2Selected, 3)}/>
                        </Grid.Column>
                        <Grid.Column className={this.setGridColumnClassname(4)}>
                            <Menu
                                borderless={true}
                                vertical={true}
                                fluid={true}
                                className={'rail-sub-menu'}
                                children={this.getMenuOptions(this.state.menu3Selected, 4)}/>
                        </Grid.Column>
                        <Grid.Column className={this.setGridColumnClassname(5)}>
                            <Menu
                                borderless={true}
                                vertical={true}
                                fluid={true}
                                children={this.getMenuOptions(this.state.menu4Selected, 5)}
                                className={'rail-sub-menu'}/>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Rail>
        )
    }
}